import React from "react";
import {BetweenRoundsComponent} from "../BetweenRoundsComponent/BetweenRoundsComponent";

import './MessageComponent.css';

export const MessageComponent = ()=>{

  return<div className="message">
    <BetweenRoundsComponent/>
  </div>
}